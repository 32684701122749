import { isAuthenticated } from '#imports';

export default defineNuxtRouteMiddleware((from, to) => {
  const isAuth = isAuthenticated();
  logger().debug('[middleware] guest', !isAuth);
  if (isAuth) {
    let redirect: string = '/projects';
    if (to.query.redirect && to.query.redirect.indexOf('/auth') !== 0) {
      redirect = to.query.redirect as string;
    }
    return navigateTo({
      path: redirect,
    });
  }
});
